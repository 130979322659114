import React from "react";

const Header = () => {
  return (
    <div>
      <header className="header-container py-4">
        <div className="container">
          <div className="row py-2">
            <div className="col">
              <div className="d-flex align-items-center h-100">
                <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
                  <img src="/assets/img/logo.png" alt="Logo" srcset="" />
                </a>
              </div>
            </div>
            <div className="col-9">
              <div className="">
                <nav class="navbar navbar-expand-lg  w-100">
                  <div class="container-fluid">
                    <button
                      class="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarTogglerDemo01"
                      aria-controls="navbarTogglerDemo01"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                      class="collapse navbar-collapse"
                      id="navbarTogglerDemo01"
                    >
                      <ul class="navbar-nav navbar-container me-auto mb-2 mb-lg-0">
                        <li class="nav-item border-end">
                          <a
                            class="nav-link activet text-white"
                            aria-current="page"
                            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
                          >
                            Home
                          </a>
                        </li>
                        <li class="nav-item border-end">
                          <a
                            class="nav-link"
                            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
                          >
                            Get Back Every Deposit
                          </a>
                        </li>
                        <li class="nav-item border-end">
                          <a
                            class="nav-link "
                            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
                            aria-disabled="true"
                          >
                            TOP 10 ONLINE CASINO
                          </a>
                        </li>
                        <li class="nav-item border-end">
                          <a
                            class="nav-link "
                            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
                            aria-disabled="true"
                          >
                            Get Free 120 PHP
                          </a>
                        </li>
                        <li class="nav-item border-end">
                          <a
                            class="nav-link "
                            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
                            aria-disabled="true"
                          >
                            How To Login 63JILI Account
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link "
                            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
                            aria-disabled="true"
                          >
                            More
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className="col d-flex justify-content-end align-items-center">
              <ul className="d-flex gap-3">
                <li className="list-unstyled">
                  <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
                    <img
                      src="/assets/img/alt_text_label_Facebook.webp"
                      alt="facebook"
                      srcset=""
                    />
                  </a>
                </li>
                <li className="list-unstyled">
                  <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
                    <img
                      src="/assets/img/9028a7_1436dbd8a99d4a609af9ac5b2e84ef33~mv2.webp"
                      alt="facebook"
                      srcset=""
                    />
                  </a>
                </li>
                <li className="list-unstyled">
                  <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
                    <img
                      src="/assets/img/alt_text_label_Instagram.webp"
                      alt="facebook"
                      srcset=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
