import React from "react";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="img d-flex justify-content-center">
            <img
              src="https://static.wixstatic.com/media/9028a7_8dd9165d545548748b7ab43d357b1016~mv2.png/v1/fill/w_600,h_71,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SITE%20LOGO.png"
              alt="footer icon"
              srcset=""
            />
          </div>
          <a
            className="nav-link footer-link"
            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
          >
            www.63jili.com
          </a>
          <div className="d-flex justify-content-center">
            <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
              <img
                src="https://static.wixstatic.com/media/9028a7_20b79bb65ef24d76a24a1ed2a4573e19~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9028a7_20b79bb65ef24d76a24a1ed2a4573e19~mv2.png"
                alt="footer-"
                className="mx-2"
                srcset=""
              />
            </a>
            <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
              <img
                src="https://static.wixstatic.com/media/11062b_a84995cc8b024f4ea398f5744a56bc27~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/alt_text_label_Facebook.png"
                alt="footer-"
                className="mx-2"
                srcset=""
              />
            </a>
            <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
              <img
                src="https://static.wixstatic.com/media/9028a7_1436dbd8a99d4a609af9ac5b2e84ef33~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9028a7_1436dbd8a99d4a609af9ac5b2e84ef33~mv2.png"
                alt="footer-"
                className="mx-2"
                srcset=""
              />
            </a>
            <a href="https://bj88ph.live/af/Zd946zCP/jili-asean">
              <img
                src="https://static.wixstatic.com/media/11062b_9b5a3b3607694630a7253c5fc4ff6476~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/alt_text_label_Instagram.png"
                alt="footer-"
                className="mx-2"
                srcset=""
              />
            </a>
          </div>
          <a
            className="nav-link footer-link"
            href="https://bj88ph.live/af/Zd946zCP/jili-asean"
          >
            ©2023 by jiliasean
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
