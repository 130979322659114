import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";
const Banner = () => {
  return (
    <div>
      <section>
        <div className="container py-5">
          <div className="d-flex justify-content-center">
            <img src="/assets/img/For New Member.webp" alt="banner" srcset="" />
          </div>

          <div className="d-flex justify-content-center">
            <div classname="btn-register">
              <button
                className="register-btn d-flex align-items-center "
                onClick={() =>
                  (window.location.href =
                    "https://bj88ph.live/af/Zd946zCP/jili-asean")
                }
              >
                Register Now{" "}
                <HiArrowLongRight className="ms-2 fs-1 text-white" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
