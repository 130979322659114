import React from 'react'
import Banner from '../section/banner'
import Gamelist from '../section/gamelist'
import Winning from '../section/Winning'

const Home = () => {
  return (
    <div>
      <main>
        <Banner/>
        <Gamelist/>
        <Winning/>
      </main>
    </div>
  )
}

export default Home