import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectFlip, Pagination, Navigation } from "swiper/modules";
const Cube = () => {
  return (
    <div>
      <Swiper
        effect={"flip"}
        grabCursor={true}
        loop={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="https://static.wixstatic.com/media/9028a7_25969c449a4344e381c1a107aba6a838~mv2.jpg/v1/fill/w_570,h_471,al_c,q_80,usm_0.66_1.00_0.01/9028a7_25969c449a4344e381c1a107aba6a838~mv2.jpg"
            alt="images"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://static.wixstatic.com/media/9028a7_0ae72fb76a2e4bd0af9bda9130d19a31~mv2.jpg/v1/fill/w_570,h_471,al_c,q_80,usm_0.66_1.00_0.01/9028a7_0ae72fb76a2e4bd0af9bda9130d19a31~mv2.jpg"
            alt="iamges"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Cube;
