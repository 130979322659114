import React from "react";
import { LiaStarSolid } from "react-icons/lia";
import Cube from "../components/carousel/Cube";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const Winning = () => {
  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-around">
          <div className="stars">
            <LiaStarSolid className="fs-1 starsss" />
          </div>
          <div className="stars">
            <LiaStarSolid className="fs-1 starsss" />
          </div>
        </div>

        <hr className="d-flex justify-content-center mx-auto" />

        <h3
          className="my-4 h1 fw-bolder text-center"
          style={{ fontFamily: "suez one,serif", color: "rgb(212,174,76)" }}
        >
          Winning For Everyone
        </h3>
        <div className="">
           <div className="cube">
            <Cube/>
           </div>
        </div>
       
      </div>
    </div>
  );
};

export default Winning;
