import React from "react";

const Gamelist = () => {
  return (
    <div>
      <section>
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-end">
              <div class="card" style={{ width: "75%" }}>
                <img
                  src="/assets/img/game1.jpg"
                  class="card-img-top"
                  alt="games"
                />
                <div class="card-body sb-description">
                  <h5 class="card-title">Enjoy Daily Chance</h5>
                  <p class="card-text desc">Enjoy Daily Chance</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div class="card" style={{ width: "75%" }}>
                <img
                  src="/assets/img/game2.jpg"
                  class="card-img-top"
                  alt="games"
                />
                <div class="card-body sb-description">
                  <h5 class="card-title">Multiplied by 3x</h5>
                  <p class="card-text desc">
                    Multiplied by 3x whatever deposit
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-6 d-flex justify-content-end">
              <div class="card" style={{ width: "75%" }}>
                <img
                  src="/assets/img/game3.jpg"
                  class="card-img-top"
                  alt="games"
                />
                <div class="card-body sb-description">
                  <h5 class="card-title">200% Daily Bounus</h5>
                  <p class="card-text desc">
                    The Chance to get back every deposit 200% Daily Bonus
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div class="card" style={{ width: "75%" }}>
                <img
                  src="/assets/img/game4.jpg"
                  class="card-img-top"
                  alt="games"
                />
                <div class="card-body sb-description">
                  <h5 class="card-title">Get 200% BONUS</h5>
                  <p class="card-text desc">
                    Get a 200% Bonus Every Day at Our Online Casino!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-6 d-flex justify-content-end">
              <div class="card" style={{ width: "75%" }}>
                <img
                  src="/assets/img/game5.jpg"
                  class="card-img-top"
                  alt="games"
                />
                <div class="card-body sb-description">
                  <h5 class="card-title">Refer Friend</h5>
                  <p class="card-text desc">
                    Win Big By Referring Your Friends!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div class="card" style={{ width: "75%" }}>
                <img
                  src="/assets/img/game6.jpg"
                  class="card-img-top"
                  alt="games"
                />
                <div class="card-body sb-description">
                  <h5 class="card-title">We are build to trust with you</h5>
                  <p class="card-text desc">
                    (How to Gamble Responsibly at Online Casinos)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gamelist;
